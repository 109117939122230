const _paq = window._paq = window._paq || [];
_paq.push(['trackPageView']);
_paq.push(['enableLinkTracking']);
(function() {
    var u="//matomoextpro.oebb.at/matomo/";
    var id=document.querySelector('[data-analytics-id]') ? document.querySelector('[data-analytics-id]').getAttribute('data-analytics-id') : null;
    if (!id) return;
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', id]);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})();
